<template>
  <div class="p-4 pb-3 rounded-md border border-gray-200 cursor-pointer grow">
    <div class="gap-3" @click="onClickSettingPack(item)">
      <div class="gap-1">
        <div class="font-semibold text-sm leading-5 line-clamp-1 break-words">
          {{ item.name }}
        </div>
        <div
          class="text-xs leading-4 text-gray-500 mt-1 line-clamp-2 break-words min-h-8"
        >
          {{ item.description }}
        </div>
      </div>
      <div :class="['flex flex-start border-gray-200 pt-3', { 'border-b pb-3': !!$slots.footer }]">
        <div
          v-for="setting in settingItems"
          :key="setting.name"
          class="flex items-center rounded-md p-1 mr-1.5"
          :class="setting.bgColor"
        >
          <component :is="setting.icon" />
          <span
            v-if="Object.keys(item).includes(setting.name)"
            class="text-xs font-medium px-1.5"
            :class="setting.textColor"
          >
            {{ item[setting.name as keyof SettingsPack].length }}
          </span>
        </div>
      </div>
    </div>
    <slot name="footer" />
  </div>
</template>

<script setup lang="ts">
import type { SettingsPack } from '#settings-pack/types'
import { settingItems } from '#core/constant'

defineProps({
  item: {
    type: Object as PropType<SettingsPack>,
    required: true,
  },
})
const emit = defineEmits<{
  (e: 'click', settingsPack: SettingsPack): void
}>()

const onClickSettingPack = (settingsPack: SettingsPack) => {
  emit('click', settingsPack)
}
</script>
